@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-Light.eot");
  src: url("../fonts/SourceSansPro/SourceSansPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SourceSansPro/SourceSansPro-Light.woff2") format("woff2"),
    url("../fonts/SourceSansPro/SourceSansPro-Light.woff") format("woff"),
    url("../fonts/SourceSansPro/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-Bold.eot");
  src: url("../fonts/SourceSansPro/SourceSansPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SourceSansPro/SourceSansPro-Bold.woff2") format("woff2"),
    url("../fonts/SourceSansPro/SourceSansPro-Bold.woff") format("woff"),
    url("../fonts/SourceSansPro/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-SemiBold.eot");
  src: url("../fonts/SourceSansPro/SourceSansPro-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SourceSansPro/SourceSansPro-SemiBold.woff2") format("woff2"),
    url("../fonts/SourceSansPro/SourceSansPro-SemiBold.woff") format("woff"),
    url("../fonts/SourceSansPro/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-Regular.eot");
  src: url("../fonts/SourceSansPro/SourceSansPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SourceSansPro/SourceSansPro-Regular.woff2") format("woff2"),
    url("../fonts/SourceSansPro/SourceSansPro-Regular.woff") format("woff"),
    url("../fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro/SourceSansPro-Black.eot");
  src: url("../fonts/SourceSansPro/SourceSansPro-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SourceSansPro/SourceSansPro-Black.woff2") format("woff2"),
    url("../fonts/SourceSansPro/SourceSansPro-Black.woff") format("woff"),
    url("../fonts/SourceSansPro/SourceSansPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
