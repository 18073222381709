/* Navbar */
header {
  background: $navbar-bg;

  .navbar {
    font-weight: 500;
    padding: 0;
    display: block;
    .navbar-brand {
      margin-right: 0;
      img {
        width: 192px;
      }
      @media (max-width: 999px) {
        img {
          width: 170px;
        }
      }
      @media (max-width: 1024px) {
        img {
          width: 130px;
        }
      }
    }
    @media (max-width: 999px) {
      .social-media {
        display: none;
      }
    }
  }
  .navbar-top {
    padding: 24px 0;
    .navbar-top-left-menu,
    .navbar-top-right-menu {
      padding-left: 0;
      list-style: none;
      display: flex;
      margin-bottom: 0;
      .nav-item {
        display: flex;
        align-items: center;
        .nav-link {
          font-size: 16px;
          color: $navbar-menu-color;
          font-size: $navbar-font-size;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          position: relative;
          padding: 3px 12px;
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          &:hover {
            color: theme-color(info);
          }
          &:after {
            content: "";
            width: 1px;
            height: 100%;
            background: $navbar-menu-right-border;
            position: absolute;
            right: 0;
          }
        }
        &:last-child {
          .nav-link {
            &:after {
              display: none;
            }
          }
        }
        &:first-child {
          .nav-link {
            padding-left: 0;
          }
        }
      }
    }
    .navbar-top-right-menu {
      .nav-item {
        &:first-child {
          margin-right: 40px;
          .nav-link {
            padding-left: 0;
            &:after {
              display: none;
            }
          }
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
        }
      }
    }
    @media (max-width: 999px) {
      display: none;
    }
  }
  .navbar-bottom {
    padding-bottom: 24px;
    @media (max-width: 999px) {
      padding-top: 24px;
    }
    .navbar-nav {
      @media (max-width: 999px) {
        padding-top: 50px;
      }
      .nav-item {
        .nav-link {
          text-transform: uppercase;
          font-size: $navbar-font-size;
          color: $navbar-menu-color;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          padding: 0 15px;
          -webkit-transition: all 0.5s ease-out;
          -moz-transition: all 0.5s ease-out;
          -o-transition: all 0.5s ease-out;
          transition: all 0.5s ease-out;
          &:hover {
            color: theme-color(info);
          }
          @media (max-width: 999px) {
            padding: 15px 15px;
          }
          @media (max-width: 1024px) {
            padding: 0 12px;
          }
        }
        // &.active {
        //   > .nav-link {
        //     color: theme-color(dark);
        //   }
        // }
      }
    }
    .navbar-toggler {
      color: rgba(255, 255, 255, 0.9);
      border-color: rgba(255, 255, 255, 0.9);
      background: rgba(255, 255, 255, 0.9);
    }
  }
}

.social-media {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 22px;
  li {
    padding: 0 4px;
    a {
      padding: 2px 4px;
      background: theme-color(dark);
      border-radius: 4px;
      color: $white;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      &:hover {
        background: $white;
        color: theme-color(dark);
      }
      i {
        font-size: 14px;
      }
    }
  }
}

.sticky {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  background: $navbar-bg;
  transition: top 3s;
  box-shadow: none;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  padding: 24px;
  @media (max-width: 999px) {
    // position: static;
  }
}
.navbar-close {
  display: none;
}
@media (max-width: 999px) {
  .navbar-nav {
    .nav-item {
      padding-left: 0px;
    }
  }
  .collapse {
    background: $navbar-bg;
    z-index: 99;
  }
  .navbar-collapse {
    position: fixed;
    right: -100%;
    top: 0px;
    height: 100%;
    text-align: center;
    padding: 20px 10px;
    transition: all 0.7s ease-in-out;
    z-index: 101;
    width: 290px;
    overflow: auto;
    @media (max-width: 768px) {
      width: 290px;
    }
    @media (max-width: 567px) {
      width: 290px;
    }
    button {
      margin-bottom: 10px;
    }
  }
  .collapse:not(.show) {
    display: block;
  }
  .show {
    right: 0;
  }
  .navbar-close {
    background: transparent;
    border: none;
    position: fixed;
    right: 20px;
    top: 20px;
    display: none;
    font-size: 20px;
    color: $white;
  }
}
