/* Buttons */

.badge-outline-dark {
  font-size: 10px;
  line-height: normal;
  padding: 5px 10px;
  font-weight: normal;
  border-radius: 2px;
  color: $dark;
  border: 1px solid $dark;
  font-weight: 600;
}
