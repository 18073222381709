/* Typography */

body {
  font-size: 1rem;
  font-family: $type1;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1.3;
}
p {
  font-size: $default-font-size;
  margin-bottom: 0.5rem;
  line-height: 1.8;
}
h1,
.h1 {
  font-size: 32px;
}
h2,
.h2 {
  font-size: 24px;
}
h3,
.h3 {
  font-size: 20px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 12px;
}
.display-1 {
  font-size: 3.5rem;
  @media (max-width: 991px) {
    font-size: 3rem;
  }
}
.display-2 {
  font-size: 2.5rem;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}
.display-3 {
  font-size: 2rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}
.display-4 {
  font-size: 1.5rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}
.display-5 {
  font-size: 1.25rem;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}
// .blockquote {
//   padding: 1.25rem;
//   border: 1px solid $border-color;
// }

// address {
//   p {
//     margin-bottom: 0;
//   }
// }

// Page header
// .page-header {
//   margin-bottom: 2.5rem;
//   .page-title {
//     margin-bottom: 0;
//     line-height: 0.85;
//   }
// }

.font-weight-medium {
  font-weight: 500;
}
